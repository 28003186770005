<template>
 <div id="fuse" class="fuse" v-title data-title="产教融合">
  <!-- 页面导航 -->
  <menu-components type="fuse"/>

  <!-- 页面主体 -->
  <main class="main">
   <!--banner-->
   <section class="home-banner">
    <el-carousel :autoplay="false" ref="carousel" :interval="5000" arrow="always" height="6.02rem">
     <el-carousel-item v-for="(item, index) in carouselList" :key="index">
      <div class="home-banner-img" style="padding-left: 12.5rem" :style="{backgroundImage: 'url('+ item.img + ')'}">
       <h2>{{item.txt}}</h2>
       <p style="margin-top:.2rem;margin-bottom:.5rem">{{item.txt4}}</p>
       <el-button @click="jumpUrl" class="other-p-bt">加入我们&nbsp;  <i class="el-icon-arrow-right"></i></el-button>
      </div>
     </el-carousel-item>
    </el-carousel>
   </section>

   <!-- 俱乐部介绍 -->
   <section class="fuse-1">
    <div class="container">
<!--     <ul class="flex align-center" style="overflow: inherit">
      <li v-for="(item, index) in fuse1List" class=""
          :class="fuse1Active == item ? 'active' : ''"
          @click="fuse1Active = item"
          :key="index">
       <h2>{{item}}</h2>
       <p v-if="fuse1Active == item"></p>
      </li>
     </ul>-->
     <el-row :gutter="150"  class="plan-1-b" style="margin-top:.67rem">
      <el-col :sm="24" :md="12">
       <div class="plan-1-b-l plan-title">
        <template>
         <div>{{fuse1Details.title}}</div>
         <h2 style="margin-bottom:.2rem;">{{fuse1Details.title1}}</h2>
         <span class="plan-title-line"></span>
        </template>
        <p style="margin-bottom:0">{{fuse1Details.txt}}</p>
        <p>{{fuse1Details.txt1}}</p>
        <el-button @click="jumpUrl">加入我们&nbsp;  <i class="el-icon-arrow-right"></i></el-button>
       </div>
      </el-col>
      <el-col :sm="24" :md="12">
       <div class="plan-1-b-r-d " v-if="fuse1Details.img">
       <img :src="fuse1Details.img" alt="">
      </div>
      </el-col>
     </el-row>
    </div>
   </section>

   <!-- 你将获得什么 -->
   <section class="fuse-2">
    <div class="container">
     <div class="">
      <div class="plan-1-b-l plan-title">
       <template>
        <div>{{fuse2Details.title}}</div>
        <h2>{{fuse2Details.title1}}</h2>
       </template>
      </div>
      <el-row :gutter="60" class="fuse-2-ul">
       <el-col v-for="(item, index) in fuse2List" :key="index" class="fuse-2-li" :xs="24" :md="8" >
        <div class="flex" style="width: auto">
         <img :src="item.img" alt="">
         <div>
          <h2>{{item.title}}</h2>
          <p>{{item.txt}}</p>
         </div>
        </div>

       </el-col>
      </el-row>



     </div>
    </div>
   </section>

  <!-- 我们寻找什么样的你-->
   <section class="fuse-3">
    <div class="container">
     <el-row :gutter="150"  class="plan-1-b" style="margin-top:.67rem">
      <el-col :sm="24" :md="13">
      <div class="plan-1-b-l plan-title">
       <template>
        <div>{{fuse3Details.title}}</div>
        <h2>{{fuse3Details.title1}}</h2>
       </template>
       <p style="margin-bottom:0">{{fuse3Details.txt}}</p>
       <p style="margin-bottom:0">{{fuse3Details.txt2}}</p>
       <p>{{fuse3Details.txt3}}</p>
      </div>
      </el-col>
      <el-col :sm="24" :md="11">
      <div class="plan-1-b-r-d " style="margin-top:0" v-if="fuse3Details.img">
       <img :src="fuse3Details.img" alt="">
      </div>
      </el-col>
     </el-row>
    </div>
   </section>


  </main>

  <!-- 页面底部 -->
  <footer-components style="margin-top:0"/>
 </div>
</template>

<script>
import MenuComponents from "@/components/Menu/index.vue";
import FooterComponents from "@/components/Footer/index.vue";
export default {
  name: 'Fuse',
  components: {
   MenuComponents,
   FooterComponents
  },
  mixins: [],
  data () {
    return {
     // 轮播列表
     carouselList: [
        { img: require('@/assets/images/04.jpg'), txt: '产教融合', txt4: '深度校企合作，人才联合培养', jumpUrl: ''},
     ],
     // 当前轮播下标
     initialIndex: 0,
     // 俱乐部介绍
     fuse1List: ['大学掌职前实训俱乐部'],
     fuse1Active: '大学掌职前实训俱乐部',
     // 当前方案概述详情
     fuse1Details: {
      title: 'Club introduction',
      title1: '大学掌职前实训俱乐部',
      txt: '大学掌是河南安冉云网络科技有限公司旗下品牌，主要从事多网融合服务、云网融合开发、网安融合保障，高校信息化软件定制开发，数字化生态服务等业务，是大型园区运营服务引领者。用户规模60余万户，资产规模超2.5亿元，是省内十大最具成长的互联网企业！',
      txt1: '大学掌职前实训俱乐部，依托安冉云公司卓越的企业文化，强劲的科技创新能力，优质的行业资源，与各大院校深度合作，建立校内外企业合作网络，为成员提供系统化的技能培训，实战化的岗位实践，专业化的职场引领，为学校人才培养、实践教学、就业服务提供新途径和增值服务，协助学校提高人才培养质量和就业水平。',
      img: require('@/assets/images/19.fw.png')
     },
     fuse2Details: {
      title: 'What You Will Gain',
      title1: '你将获得什么？',
     },
     fuse2List: [
      {img: require('@/assets/images/11.fw.png'), title: '学习与分享', txt:'定期举办技术讲座、研讨会和分享会，让你了解最新的软件技术和行业动态'},
      {img: require('@/assets/images/12.fw.png'), title: '实践与提升', txt:'参与真实的软件项目，将理论知识应用到实践中，提升你的编程能力和团队协作力'},
      {img: require('@/assets/images/13.fw.png'), title: '项目津贴', txt:'根据完成实践项目的工作量进行发放报酬'},
     ],
     fuse3Details: {
      title1: '我们寻找什么样的你?',
      txt: `1、具有较强的自我驱动力，逻辑思维力，团队协作力、执行力，有班级或社团干部经验、中共党员，优先录用`,
      txt2: '2、对职业发展有浓厚兴趣，专注于毕业即就业的职业梦想，具备所选科目的基础知识，身心健康，无违规违纪情况',
      txt3: '3、除具体实践科目所需相关专业之外，鼓励不同专业学生加入，促进跨专业的交流与合作',
      img: require('@/assets/images/21.fw.png')
     },
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {
   /* 轮播切换 */
   changeCarousel (val) {
    this.initialIndex = val;
    this.$refs.carousel.setActiveItem(val)
   },
   /* 切换上一个 */
   leftPrev () {
    this.$refs.carousel.prev(this.initialIndex)
   },
   /* 切换下一个 */
   rightNext () {
    this.$refs.carousel.next(this.initialIndex)
   },
   // 鼠标进入 进出
   handleMouse(index) {
    this.homeS3Index = index
   },
   /* 我要合作 */
   jumpUrl () {
    window.open('https://dataex.autewifi.com/#/collect/a4bd42b3591f40dba533de52b20e62ce')
   }
  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
